var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ml-8"},[_c('v-col',{staticClass:"mt-16",attrs:{"cols":"12","sm":"3"}},_vm._l((_vm.btns1),function(ref,index){
var texto = ref[0];
var rounded = ref[1];
return _c('v-menu',{key:texto,staticClass:"hidden-sm-and-down",attrs:{"rounded":rounded,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down dark--text",attrs:{"color":_vm.colors[index],"text":""}},'v-btn',attrs,false),on),[_vm._v(" Bookkeeping ")])]}}],null,true)},[_c('v-list',{attrs:{"if":_vm.departamentos.bookkepping}},_vm._l((_vm.toolsBookkeeping),function(item){return _c('v-list-item',{key:item,attrs:{"link":""},on:{"click":function($event){return _vm.EnviarAccion(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)}),1)],1)}),1),_c('v-col',{staticClass:"mt-16",attrs:{"cols":"1","sm":"3"}},_vm._l((_vm.btns6),function(ref,LoansIndex){
var texto = ref[0];
var rounded = ref[1];
return _c('v-menu',{key:texto,staticClass:"ml-7 hidden-sm-and-down",attrs:{"rounded":rounded,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-7 hidden-sm-and-down",attrs:{"color":_vm.colors[LoansIndex],"text":""}},'v-btn',attrs,false),on),[_vm._v(" Loans ")])]}}],null,true)},[_c('v-list',{attrs:{"if":_vm.departamentos.loans}},_vm._l((_vm.toolsLoands),function(item){return _c('v-list-item',{key:item,attrs:{"link":""},on:{"click":function($event){return _vm.EnviarAccion(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)}),1)],1)}),1),_c('v-col',{staticClass:"mt-16",attrs:{"cols":"1","sm":"3"}},_vm._l((_vm.btns2),function(ref,index){
var tex = ref[0];
var rounded = ref[1];
return _c('v-menu',{key:tex,staticClass:"hidden-sm-and-down",attrs:{"rounded":rounded,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down dark--text",attrs:{"color":_vm.colors[index],"text":""}},'v-btn',attrs,false),on),[_vm._v(" Payroll ")])]}}],null,true)},[(_vm.departamentos.payroll)?_c('v-list',_vm._l((_vm.toolsPayroll),function(items){return _c('v-list-item',{key:items,attrs:{"link":""},on:{"click":function($event){return _vm.EnviarAccion(items)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(items)}})],1)}),1):_c('v-list',_vm._l((_vm.toolsPayrollBook),function(item){return _c('v-list-item',{key:item,attrs:{"link":""},on:{"click":function($event){return _vm.EnviarAccion(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)}),1)],1)}),1),_c('v-col',{staticClass:"mt-16",attrs:{"cols":"1","sm":"3"}},_vm._l((_vm.btns5),function(ref,taxesIndex){
var texto = ref[0];
var rounded = ref[1];
return _c('v-menu',{key:texto,staticClass:"hidden-sm-and-down",attrs:{"rounded":rounded,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down dark--text",attrs:{"color":_vm.colors[taxesIndex],"text":""}},'v-btn',attrs,false),on),[_vm._v(" Taxes ")])]}}],null,true)},[_c('v-list',{attrs:{"if":_vm.departamentos.taxes}},_vm._l((_vm.toolsTaxes),function(item){return _c('v-list-item',{key:item,attrs:{"link":""},on:{"click":function($event){return _vm.EnviarAccion(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)}),1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }