<template>
    <v-dialog v-model="estatus"  max-width="500px">
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="#EF6C00" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab> Enter an excel file </v-tab>
        <v-tab-item>
          <v-card class="px-4">
            <v-card-text>
              <v-form ref="loginForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-card-text>
                    
                    <v-file-input
                      label="Excel File"
                      v-model="inputFile"
                      outlined
                      color="indigo"
                    >

                    </v-file-input>

                    <v-select
                      :items="items"
                      label="Company Type"
                      color="indigo"
                      v-model="select"
                      outlined
                    ></v-select>

                    <v-progress-circular
                      v-if="loading"
                      indeterminate
                      color="indigo"
                    ></v-progress-circular>

                  </v-card-text>
                  <v-card-actions >
                    <v-btn color="#EF6C00" dark   @click="accion">
                      Close
                    </v-btn>
                    <v-btn color="#EF6C00" dark  @click="EnviarExcel">
                      Send
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      
    </v-dialog>
    
</template>

<script>

export default {
     props:{
        estatus: Boolean
    },
      data: () => ({
          loading: false,
          inputFile: null,
          items: [
      "Construction",
      "Ironwork",
      "Beauty Salon",
      "Bakery",
      "Restaurant",
      "Supermarket",
      "Boutique",
      "Flower Shop",
      "Plumbing",
      "Landscaping",
      "Towing",
      "TranTransportsporte",
      "Events",
      "Repairs",
      "Security",
      "Ice Cream Shop",
      "heating and air conditioning",
      "Hauling",
    ],
    select: [],
    dialogoDescarga: false,
    ctr: sessionStorage.getItem("ruta") + "/core/main.php",
    valid: true,
    dialog:false
  }),
  methods: {
    accion(){
        this.$emit('cerrar');
      },
      AbrirDialogoDescarga(){
        this.$emit('abrir');
      },EnviarExcel() {
      this.dialog=true;
      var formData = new FormData();
      var imagefile = this.inputFile;
      console.log(this.select);
      formData.append("archivo", imagefile);
      formData.append("giro", this.select);
      axios({
        method: "post",
        timeout: 60000,
        url:
          sessionStorage.getItem("ruta") +
          "/core/ControladorExcel.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(
          function (response) {
            console.log(response.data);
            
            this.inputFile = null;
            this.dialogoArchivo = false;
            this.dialogoDescarga = true;
           // this.CerrarDialogo();
           this.dialog=false;
            this.AbrirDialogoDescarga();

          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    async downloadTemplateFile() {
      axios
        .get(
          sessionStorage.getItem("ruta") +
            "/src/assets/prueba.csv",
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = file;
          link._target = "blank";
          link.click();
        });
    },
    CerrarDialogo(){
      this.dialogoDescarga=false;
    }

  },

}
</script>

<style>

</style>