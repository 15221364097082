<template>
  <v-dialog v-model="estatus" @keydown.esc="cerrar" persistent max-width="20%">
    <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
      <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
      <v-tab>
        <v-btn text x-large>Sign out<v-icon right>mdi-logout</v-icon></v-btn>
      </v-tab>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-form ref="loginForm" v-model="valid" lazy-validation>
              <v-row>
                <v-card-text>
                  <v-row cols="12" sm="3" md="6">
                    <v-col> </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="#EF6C00" large @click="Logout(), cerrar()" dark>
                    OK
                  </v-btn>

                  <v-btn large color="#EF6C00" @click="cerrar" dark> Cancel </v-btn>
                </v-card-actions>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-dialog>
</template>

<script>
import { db } from "../firebase";
export default {
  props: {
    estatus: Boolean,
  },
  data: () => ({}),
  methods: {
    Logout() {

      this.DesconectarUsuario();
      this.$emit("logout");
      
      let parametros = new URLSearchParams();
      parametros.append("accion", 2);
      parametros.append("idUsuario", sessionStorage.getItem("idUsuario"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    cerrar() {
      this.$emit("cerrar");
    },
    DesconectarUsuario() {

     db.collection("conexiones")
        .doc(sessionStorage.getItem("uid"))
        .update({
          //Nodo padre del que queremos sacar los nodos hijos
          idEmpleado: sessionStorage.getItem("uid"),
          nombre: sessionStorage.getItem("fullname"),
          tipo: 0,
          estatus:"1"
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>