<template>
   <v-dialog v-model="estatus" persistent max-width="500px">
      <v-card>
        <v-card-title> Download Excel File </v-card-title>
        <v-card-text>
          <a
            :href="ruta"
            download
            >Click Download file</a
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="indigo" text @click="accion">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
     props:{
        estatus: Boolean
    },
      data: () => ({
        ruta:sessionStorage.getItem("ruta") +"/src/assets/fastaxDocument.csv"
         
  }),
  methods: {
    accion(){
        this.$emit('cerrar');
      },
  },

}

</script>

<style>

</style>