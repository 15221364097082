import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Panel from '../views/MainPanel.vue'
import Tools from '../components/Herramientas.vue';
import sales from '../views/SalesTaxes.vue';
import client from '../views/Clientes.vue';
const salesTax = sales
const mainPanel = Panel;
const clients = client;

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path:'/TaskCenter',
    name:'TaskCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/MainPanel')
  },
  {
    path:'/tools',
    name:'Tools',
    component: Tools
  },
  {
    path: '/adminTask',
    name: 'AdminTask',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/AdministradorTareas.vue')
  },
  {
    path: '/Vendors',
    name: 'Vendors',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/Vendors.vue')
  },
  {
    path: '/Usuarios',
    name: 'Usuarios',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/Usuarios.vue')
  },
  {
    path: '/RoomChats',
    name: 'RoomChats',
     
    component: () => import(/* webpackChunkName: "about" */ '../views/RoomChats.vue')
  },
  {
    path: '/SalesTaxes',
    component: () => import(/* webpackChunkName: "about" */ '../views/SalesTaxes.vue')
  },
  {
    path: '/PrincipalCliente',
    name: 'PrincipalCliente',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/PrincipalCliente.vue')
  },
  {
    path: '/Payroll',
    name: 'Payroll',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/Payroll.vue')
  },
  {
    path: '/Bookkeeping',
    name: 'Bookkeeping',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/Bookkeeping.vue')
  },
  {
    path: '/DocumentosCliente',
    name: 'DocumentosCliente',
     
    component: () => import(/* webpackChunkName: "about" */ '../views/DocumentosCliente.vue')
  },
  {
    path: '/Clientes',
    component: () => import(/* webpackChunkName: "about" */ '../views/Clientes.vue')
  },
  {
    path: '/TaskDetail/:id',
    name: 'TaskDetail',
   
    component: () => import(/* webpackChunkName: "about" */ '../views/TaskDetail.vue')
  },
  {
    path: '/ChatNotificacion/:id',
    name: 'ChatNotificacion',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatNotificacion.vue')
  },
  {
    path: '/Companies',
    name: 'Companies',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/Catalogo.vue')
  },
  {
    path: '/Company',
    name: 'Company',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/CompanyInfo.vue')
  },
  

  
]

const router = new VueRouter({
  routes
})

export default router
