//Clase para importar firebase e inicializar en el proyecto 

//  **NOTA** firebase se tiene que instalar con el comando npm install firebase  

import firebase from "firebase/app"; // sí importas solo firebase funciona pero tienes que especificar que herramienta necesitas 
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/database'

const firebaseConfig = { //datos de conexion con firebase
    apiKey: "AIzaSyALkXRnymBRQ1adeEkKS6TquOnT8UtUnWQ",
    authDomain: "prueba-b309a.firebaseapp.com",
    databaseURL: "https://prueba-b309a.firebaseio.com",
    projectId: "prueba-b309a",
    storageBucket: "prueba-b309a.appspot.com",
    messagingSenderId: "920452446578",
    appId: "1:920452446578:web:5098093de56a44ba1745eb",
    measurementId: "G-B1N8S91TC6",
  };

  //inicializamos firebase
  firebase.initializeApp(firebaseConfig);

  const db = firebase.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();
  const data = firebase.database();


  export {db, storage, data} //exportamos 
