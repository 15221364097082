<script>
/*
 * Developer: Angel Omar Martinez Campos
 * Clase principal
 * contiene Login, registro de clientes, barra de herramientas, personal chat, notas
 *
 */
import Chat from "vue-beautiful-chat"; // libreria de Personal chat
import Vue from "vue";
import VueChatScroll from "vue-chat-scroll"; // libreria para el scrooll del chat
Vue.use(Chat);
Vue.use(VueChatScroll);
import DetalleTarea from "../components/DetalleTarea"; //Componente para mostrar el detalle de la tarea
import Excel from "../components/HerramientaExcel"; //Componente para modificar el archivo de excel
import Download from "../components/DescargarExcel"; //Componente para descargar el archivo de excel ya modificado
import Confirmacion from "../components/ConfirmacionSession"; //Componente del dialogo de confimacion de cierre de session
import { db, storage } from "../firebase";
import moment from "moment"; // libreria para dar formato a la hora y fecha
import Notas from "../components/Notas"; // Componente de notas
import toolsServices from "../components/toolsServices";
import EventBus from "../bus"; // evento bus para comunicacion entre componentes
import Clientes from "./Clientes.vue";
import TaskCenter from "../components/TaskCenter.vue";

import CloseIcon from "../assets/close-icon.png";
import OpenIcon from "../assets/logo-no-bg.svg";
import FileIcon from "../assets/file.svg";
import CloseIconSvg from "../assets/close.svg";

var time = new Date();
moment.locales("es");

export default {
  components: {
    DetalleTarea,
    Excel,
    Download,
    Confirmacion,
    Notas,
    toolsServices,
    Clientes,
    TaskCenter,
  },
  delimiters: ["${", "}"],
  data: () => ({
    taskCenterStatus: 0,
    menuChat: [{ title: "Delete" }],
    offset: true,
    active: false,
    nombreChat: "",
    fechaActualMoment: moment(Date.now()).format("ll"),
    happyBirthday: false,
    fechaActual: "",
    fechaNacimiento: "",
    filelist: [],
    loading: false,
    nombreCliente: "", // nombre del cliente para mostrar en la barra de estado
    nombreServicio: "", // nombre del servicio para mostrar en la barra de estado
    tipoUsuario: "",
    nombreEmpresa: "",
    nombreContacto: "",
    telefonoContacto: "",
    idioma: ["English", "Spanish"],
    selectIdioma: [],
    emailEmpresa: "",
    passwordEmpresa: "",
    passwordEmpresaConfirm: "",
    departamentos: {
      payroll: false,
      bookkepping: false,
      system: false,
      support: false,
      managers: false,
      loans: false,
      taxes: false,
    },
    idDepartamentos: [],
    tipo: 0,
    participanteURL: "",
    urlAvatar: "",
    dialogoFoto: false,
    fab: false,
    usuariosChat: [],
    dialogoConfirmacion: false,
    drawer: false,
    rutaLogo: sessionStorage.getItem("ruta") + "/src/assets/logo_azul.png",
    group: null,
    dialogoArchivo: false,
    dialogoDescarga: false,
    selectedItem: 1,
    inputFile: null,
    archivo: null,
    notificaciones: [],
    message: "text",
    messages: 0,
    show: false,
    rutaArchivo: "",
    rol: "",
    nombre: "",
    notify: false,
    botonlogin: "",
    iniciales: "",
    fullname: "",
    email: "", //Datos y opciones del avatar
    items: [
      "Constructora",
      "Herreria",
      "Salon de belleza",
      "Panaderia",
      "Restaurante",
      "Super mercado",
      "Boutique",
      "Floreria",
      "Plomeria",
      "Jardineria",
      "Gruas",
      "Transporte",
    ],
    select: [],
    checkbox: false,
    dialog: false,
    sesion: 0,
    tab: 0,
    tabs: [
      { name: "Login", icon: "mdi-account" },
      { name: "Create new account", icon: "mdi-account-outline" },
    ],
    valid: true,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    verify: "",
    loginPassword: "",
    loginEmail: "",
    loginEmailRules: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    emailRules: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    show1: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => (v && v.length >= 5) || "Min 8 characters",
    },
    btns1: [["Custom1", "b-xl"]],
    btns2: [["Custom2", "b-xl"]],
    btns3: [["Custom3", "b-xl"]],
    btns4: [["Custom4", "b-xl"]],
    btns5: [["Custom5", "b-xl"]],
    btns6: [["Custom6", "b-xl"]],
    colors: ["", "error", "teal darken-1"],
    toolsAdmin: ["Task Manager", "Task Center", "Users", "Companies"],
    tools: ["Task Center"],

    toolsBookkeeping: [
      "Excel Editor",
      "Vendors",
      "Sales Taxes",
      "Reports",
      "Documents",
    ],

    toolsPayroll: ["Hours", "Reports", "Documents"],
    toolsCliente: ["Bookkeeping"],
    toolsPayrollBook: ["Reports", "Documents"],
    toolsLoands: ["Tools"],
    toolsTaxes: ["Tools"],
    dialogoChat: false,
    validarChat: false,
    headers: [
      {
        text: "Notes",
        align: "start",
        sortable: false,
        value: "titulo",
      },
      //{ text: "Status", value: "estatus" },
    ],
    notas: [],
    options: {
      itemsPerPage: 5,
    },
    search: "",
    dialogoNotas: false,
    editNota: [],
    dialogoDetalleTarea: false,
    estatus: false,
    idTarea: "",
    detalles: {},
    participants: [], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
    titleImageUrl: "", //http://myfastax.com/src/assets/fastaxMini.jpg
    messageList: [], // the list of the messages to show, can be paginated and adjusted dynamically
    newMessagesCount: 0,
    isChatOpen: false, // to determine whether the chat window should be open or closed
    showTypingIndicator: "", // when set to a value matching the participant.id it shows the typing indicator for the specific user
    colors: {
      header: {
        bg: "#0D47A1",
        text: "#ffffff",
      },
      launcher: {
        bg: "#0D47A1",
      },
      messageList: {
        bg: "#ffffff",
      },
      sentMessage: {
        bg: "#4e8cff",
        text: "#ffffff",
      },
      receivedMessage: {
        bg: "#eaeaea",
        text: "#222222",
      },
      userInput: {
        bg: "#f4f7f9",
        text: "#565867",
      },
    }, // specifies the color scheme for the component
    alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
    messageStyling: true,
    nodo: "",
  }),
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
  itemEmpleados: [],
  tipoEmpleado: 0,
  computed: {
    activeFab() {
      switch (this.tabs) {
        case "one":
          return { class: "purple", icon: "account_circle" };
        case "two":
          return { class: "red", icon: "edit" };
        case "three":
          return { class: "green", icon: "keyboard_arrow_up" };
        default:
          return {};
      }
    },
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
  },
  created() {
    //this.Permisos();
    //this.notificar();
    //rutasg
    sessionStorage.removeItem("idCliente");
    var URLactual = window.location;
   

    if(URLactual=="http://myfastax.com/#/"){
      this.$router.push("/TaskCenter").catch((failure) => {});
    }else{
      this.taskCenterStatus = 0;
    }

    
    EventBus.$on("add-comment", (item, nombre) => {
      this.nombreCliente = nombre;
      this.nombreServicio = item;
    });

    this.nombreServicio = "Task Center";
    this.tipoEmpleado = sessionStorage.getItem("tipo");

    //sessionStorage.setItem("ruta", "http://192.168.100.52:80/fastaxSolutions");
    //sessionStorage.setItem("ruta", "http://192.168.100.112:81/fastaxSolutions");
    //sessionStorage.setItem("ruta", "http://192.168.100.96:80/fastaxSolutions");
    sessionStorage.setItem("ruta","http://www.myfastax.com");
    //http://myfastax.com/src/assets/logo_azul.png

    this.rutaLogo =
      sessionStorage.getItem("ruta") + "/src/assets/logo_azul.png";
    // sessionStorage.removeItem("uid");

    if (localStorage.getItem("uid")) {
      sessionStorage.setItem("uid", localStorage.getItem("uid"));
      this.tipoUsuario = localStorage.getItem("tipo");
      if (localStorage.getItem("tipo") == 1) {
        this.sesion = 1;
        this.ConsultarDatosEmpleado(localStorage.getItem("idUsuario"));
      } else {
        this.sesion = 2;
        this.ConsultarDatosClientes(localStorage.getItem("idUsuario"));
      }
    } else {
      this.Logout();
    }

    //this.sesion = sessionStorage.getItem("uid");
  },

  methods: {
    ActivarTaskCenterListener() {
      EventBus.$on("hint-taskcenter", () => {
        this.taskCenterStatus = 0;
      });
    },
    EliminarMensaje(item) {
      console.log(this.nodo);
      let ref = db

        .collection(this.nodo)
        .doc(item.id)
        .delete()
        .then(function () {
          console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error al aceptar notificación: ", error);
        });
    },
    mouseOver: function () {
      this.active = !this.active;
    },
    ConsultarCumpleaños() {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 16);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            var nombre = "";
            nombre = sessionStorage.getItem("fullname");
            db.collection("noty" + sessionStorage.getItem("uid"))
              .doc("notificacionTarea" + sessionStorage.getItem("uid"))
              .set({
                //Nodo padre del que queremos sacar los nodos hijos
                name: nombre,
                type: 4,
                message: "TODAY IS " + response.data[0].nombre + "´S BIRTHDAY",
                time: Date.now(),
                idTarea: 1,
                estatus: false,
                notificado: false,
              })
              .catch((error) => {
                console.log(error);
              }); //promesa para el error
            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    CerrarNota() {
      this.dialogoNotas = false;
      this.ConsultarNotas();
    },
    EditarNotas(value) {
      this.editNota = value;
      this.dialogoNotas = true;
    },
    handleClick() {
      this.editNota = [];
      this.dialogoNotas = true;
    },
    ConsultarNotas() {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 1);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNotas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.notas = response.data;

            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AbrirDialogoChat(item) {
      this.isChatOpen = true;
      this.participants = [];
      this.titleImageUrl = item.url;
      this.participanteURL = item.url;
      this.participants.push({
        id: item.idEmpleado,
        name: item.nombre,
        imageUrl: item.url,
      });

      this.itemEmpleados = item.idEmpleado;

      sessionStorage.setItem("idEmpleadoChat", item.idEmpleado);
      this.nodo = "";

      if (
        sessionStorage.getItem("idEmpleadoChat") > sessionStorage.getItem("uid")
      ) {
        this.nodo =
          "chat-" +
          sessionStorage.getItem("idEmpleadoChat") +
          "-" +
          sessionStorage.getItem("uid");
      } else {
        this.nodo =
          "chat-" +
          sessionStorage.getItem("uid") +
          "-" +
          sessionStorage.getItem("idEmpleadoChat");
      }
      console.log(this.nodo);
      this.nombreEncargado = sessionStorage.getItem("fullname");
      this.ConsultarMensajes();
      this.enviarMensajeVacio();
    },
    CerrarDialogoChat() {
      this.dialogoChat = false;
      this.validarChat = false;
    },
    ConsultarUsuarios() {
      let ref = db
        .collection("conexiones")
        .where("estatus", "==", "1")
        .orderBy("tipo", "desc");
      ref.onSnapshot((querySnapshot) => {
        this.usuariosChat = [];
        querySnapshot.forEach((element) => {
          if (element.data().nombre != sessionStorage.getItem("fullname")) {
            this.usuariosChat.push({
              idEmpleado: element.data().idEmpleado,
              nombre: element.data().nombre,
              tipo: element.data().tipo,
              url: element.data().url,
            });
          } else {
            this.urlAvatar = element.data().url;
          }
        });
      });
    },
    Logout() {
      this.sesion = 0;
      this.botonlogin = "Log in";
      sessionStorage.removeItem("uid");
      localStorage.removeItem("uid");
      sessionStorage.setItem("uid", null);
      sessionStorage.setItem("nombre", "");
      //this.sesion = false;
      //this.botonlogin = "Log in";

      this.$router.push("/").catch((failure) => {});
      sessionStorage.removeItem("uid");
      sessionStorage.removeItem("rol");
    },
    validate() {
      if (this.$refs.loginForm.validate()) {
        // submit form to server/API here...
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    ValidarSesion() {
      if (this.sesion > 0) {
        this.AbrirDialogoConfirmacion();
      } else {
        this.dialog = true;
      }
    },
    Login() {
      this.loading = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 1);
      parametros.append("password", this.loginPassword);
      parametros.append("user", this.loginEmail);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data == false) {
              alert("email or password don't match");
              this.loading = false;
            } else {
              response.data.forEach((element) => {
                localStorage.setItem("tipo", element["tipo"]);
                sessionStorage.setItem("tipo", element["tipo"]);
                this.tipoUsuario = element["tipo"];

                if (element["tipo"] == 1) {
                  this.sesion = 1;
                  this.ConsultarDatosEmpleado(element["idUsuario"]);
                  //this.taskCenterStatus = 1;
                 
                
                } else {
                  this.sesion = 2;
                  this.ConsultarDatosClientes(element["idUsuario"]);
                 
                }
              });
              this.loading = false;
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarDatosEmpleado(idUsuario) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);
      parametros.append("idUsuario", idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.botonlogin = "Log out";
              console.log(element["idEmpleado"]);
              localStorage.setItem("uid", element["idEmpleado"]);
              sessionStorage.setItem("uid", element["idEmpleado"]);
              sessionStorage.setItem("nombre", element["nombre"]);
              sessionStorage.setItem("aPaterno", element["aPaterno"]);
              sessionStorage.setItem("correo", element["correo"]);
              sessionStorage.setItem("rol", element["rol"]);
              sessionStorage.setItem("tipo", element["tipo"]);
              localStorage.getItem("uid", element["tipo"]);
              sessionStorage.setItem("idUsuario", element["idUsuario"]);
              localStorage.setItem("idUsuario", element["idUsuario"]);
              sessionStorage.setItem("departamento", element["departamento"]);

              this.fechaNacimiento = element["birthday"];

              if (this.fechaNacimiento === "1") {
                this.happyBirthday = true;
              } else {
                this.happyBirthday = false;
              }

              this.tipo = sessionStorage.getItem("tipo");
              this.idDepartamentos.push({
                idDepartamento: element["idDepartamento"],
              });

              sessionStorage.setItem(
                "idDepartamento",
                JSON.stringify(this.idDepartamentos)
              );

              //this.ObtenerIniciales(element["nombre"], element["aPaterno"])
              let name = element["nombre"].charAt(0);
              let lastName = element["aPaterno"].charAt(0);
              let iniciales = name + lastName;
              sessionStorage.setItem("iniciales", iniciales);
              sessionStorage.setItem(
                "fullname",
                element["nombre"] + " " + element["aPaterno"]
              );
              this.iniciales = iniciales;
              this.fullname = sessionStorage.getItem("fullname");
              this.email = sessionStorage.getItem("correo");

              this.nombre = element["nombre"];

              this.dialog = false;
              this.rol = element["rol"];

              this.$router.push("/TaskCenter").catch((failure) => {});
              //this.ConsultarTareasVencidas();
              this.Notificar();
              this.ConsultarUsuarios();
              this.ConsultarNotas();
              this.ConectarUsuario();
              this.ConsultarCumpleaños();
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarDatosClientes(idUsuario) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idUsuario", idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.botonlogin = "Log out";

              sessionStorage.setItem("uid", element["idCliente"]);
              sessionStorage.setItem("nombre", element["nombre"]);
              sessionStorage.setItem("correo", element["correo"]);
              sessionStorage.setItem("idUsuario", element["idUsuario"]);

              sessionStorage.setItem("fullname", element["nombre"]);

              this.fullname = sessionStorage.getItem("fullname");
              this.email = sessionStorage.getItem("correo");

              this.nombre = element["nombre"];
              this.dialog = false;

              this.$router.push("/PrincipalCliente").catch((failure) => {});
              //this.ConsultarTareasVencidas();
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    AccionMenu(opcion) {
      switch (opcion) {
        case "Task Manager":
          this.nombreServicio = "Task Manager";
          // this.nombreServicio = "";
          this.nombreCliente = "";
          this.$router.push("/adminTask").catch((failure) => {});
          this.taskCenterStatus = 0;
          break;

        case "Task Center":
          this.nombreServicio = "Task Center";
          this.nombreCliente = "";
          this.$router.push("/TaskCenter").catch((failure) => {});
          this.taskCenterStatus = 0;

          break;

        case "Users":
          this.nombreServicio = "Users";
          this.nombreCliente = "";
          this.$router.push("/Usuarios").catch((failure) => {});
          this.taskCenterStatus = 0;

          break;

        case "Companies":
          this.nombreServicio = "Companies";
          this.nombreCliente = "";
          this.$router.push("/Companies").catch((failure) => {});
          this.taskCenterStatus = 0;

          break;

        default:
          break;
      }
    },
    ObtenerIniciales(nombre, aPaterno) {
      var cadena = nombre + " " + aPaterno,
        separador = " ", // un espacio en blanco
        arregloDeSubCadenas = cadena.split(separador); // SEPARA EL NOMBRE EN CADENAS INDIVIDUALES

      // IMPRIME EL NOMBRE INGRESADO

      /* for (let x = 0; x < arregloDeSubCadenas.length; x++) {
        
      }*/
      // IMPRIME LA PRIMERA LETRA DE CADA CADENA

      for (let j = 0; j < arregloDeSubCadenas.length; j++) {
        subCadena = arregloDeSubCadenas[j].substring(0, 1);
      }
    },
    ConsultarTareasVencidas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 15);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.notificaciones = [];
            this.messages = 0;
            response.data.forEach((element) => {
              this.notificaciones.push("Tarea vencida: " + element.nombreTarea);
              this.messages += 1;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    AccederRol() {
      this.rol = sessionStorage.getItem("rol");
    },
    CerrarDialogo() {
      this.dialogoArchivo = false;
    },
    AbrirDialogoDescarga() {
      this.dialogoDescarga = true;
    },
    CerrarDialogoDescarga() {
      this.dialogoDescarga = false;
    },
    AbrirDialogoConfirmacion() {
      this.dialogoConfirmacion = true;
    },
    CerrarDialogoConfirmacion() {
      this.dialogoConfirmacion = false;
    },
    Notificar() {
      //este metodo es para notificar al usuario
      var nombre = sessionStorage.getItem("uid");
      moment.locales("es");
      let ref = db
        .collection("noty" + nombre)
        .orderBy("time", "desc")
        .limit(18); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        this.messages = 0;
        this.notificaciones = [];

        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes

          if (element.data().estatus) {
            this.messages = this.messages + 1;
            if (element.data().notificado === false) {
              this.notificacionPush(element);
              this.CambiarEstatusNotificiacionActiva(element.id);
            }
          }

          this.notificaciones.push({
            mensaje:
              element.data().message +
              "  " +
              moment(element.data().time).format("ll"),
            id: element.id,
            time: moment(element.data().time).format("ll"),
            type: element.data().type,
            idTarea: element.data().idTarea,
            idEmpleado: element.data().idEmpleado,
            nombre: element.data().name,
            estatus: element.data().estatus,
          });
        });
      });
    },
    EliminarNotificacion(item) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db

        .collection("noty" + nombre)
        .doc(item.id)
        .delete()
        .then(function () {
          this.AccionMenu(item.type);

          this.Notificar();
          console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error al aceptar notificación: ", error);
        });
    },
    AbrirEnlanceNotificacion(item) {
      this.notify = false; //Cerramos el dialogo que muestra las notificaciones.
      this.CambiarEstatusNotificacion(item);
      if (item.type == 1) {
        // validamos que tipo de notificacion es 1 notificacion de tarea nueva o chat de la tarea
        this.AbrirDialogo(item);
      } else if (item.type == 2) {
        // validamos si es 2 es una notificacion del chat personal.
        //cambiamos el estatus de la notificacion para que no la marque como nueva y cambie su color
        //this.isChatOpen = true;
        this.AbrirDialogoChat(item); //abrimos el dialogo del chat personal.
      }
    },
    cerrarDialogo() {
      this.estatus = false;
      this.dialogoDetalleTarea = false; //este es la variable que define el estatus del componente detalleTarea FALSE/TRUE
    },
    AbrirDialogo(item) {
      //este es la variable que define el estatus del componente FALSE/TRUE
      this.ConsultarDatos(item);
    },
    ConsultarDatos(item) {
      let parametros = new URLSearchParams();

      parametros.append("accion", 20);
      parametros.append("idTarea", item.idTarea);
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.detalles = element;
              this.estatus = true;
              this.dialogoDetalleTarea = true;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConectarUsuario() {
      console.log(sessionStorage.getItem("fullname"));
      db.collection("conexiones")
        .doc(sessionStorage.getItem("uid"))
        .update({
          //Nodo padre del que queremos sacar los nodos hijos
          idEmpleado: sessionStorage.getItem("uid"),
          nombre: sessionStorage.getItem("fullname"),
          tipo: 1,
          estatus: "1",
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          this.AgregarUsuarioFirebase(); //En caso de que el usuario no este registrado en la plataforma de firebase arroja un error si lo rregistra con esta funcion
        });
    },
    AgregarUsuarioFirebase() {
      console.log(sessionStorage.getItem("fullname"));
      db.collection("conexiones")
        .doc(sessionStorage.getItem("uid"))
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          idEmpleado: sessionStorage.getItem("uid"),
          nombre: sessionStorage.getItem("fullname"),
          tipo: 1,
          estatus: "1",
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendMessage(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1;
        this.onMessageWasSent({
          author: "support",
          type: "text",
          isEdited: true,
          data: { text },
        });
      }
    },
    onMessageWasSent(message) {
      if (message.type == "file") {
        this.BuscarImagen(message);
      } else {
        this.enviarMensaje(message);
      }
      // called when the user sends a message
      this.messageList = [...this.messageList, message];
    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true;
      this.newMessagesCount = 0;
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType() {},
    editMessage(message) {
      const m = this.messageList.find((m) => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },

    downloadItem(name) {
      storage
        .ref()
        .child("Archivos" + this.nodo)
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    cerrar() {
      this.$emit("cerrar");
    },
    enviarMensaje(message) {
      // var mensaje = this.messageForm.content;
      //this.messageForm.content = "";
      db.collection(this.nodo)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          author: message.author,
          message: message.data.text,
          me: true,
          url: "",
          download: false,
          time: Date.now(),
          type: "text",
          name: sessionStorage.getItem("fullname"),
          idEmpleado: sessionStorage.getItem("uid"),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      // this.ConsultarMensajes();
      this.mensaje = [];
      this.Enviarnoty(sessionStorage.getItem("idEmpleadoChat"));
      //this.ConsultarColaboradores();
    },
    enviarMensajeVacio() {
      // var mensaje = this.messageForm.content;
      //this.messageForm.content = "";
      db.collection(this.nodo)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          author: "",
          message: "",
          me: true,
          url: "",
          download: false,
          time: "",
          type: "text",
          name: "",
          idEmpleado: "",
          status: 0,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      // this.ConsultarMensajes();
      this.mensaje = [];
      //this.ConsultarColaboradores();
    },
    ConsultarMensajes() {
      moment.locales("es");
      console.log(this.nodo);
      let ref = db
        .collection(this.nodo)
        .where("status", "==", 1)
        .orderBy("time"); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        this.messageList = [];
        querySnapshot.forEach((element) => {
          console.log(element);

          //forEach que recorre toda la coleccion de mensajes
          // { type: 'text', author: `me`, data: { text: `simon dice` } },
          //{ type: 'text', author: `user1`, data: { text: `hola, como estas ?` }

          let nombre = "";
          if (element.data().name == sessionStorage.getItem("fullname")) {
            nombre = "me";
          } else {
            nombre = element.data().name;
          }

          if (element.data().type == "file") {
            this.messageList.push({
              id: element.id,
              author: nombre,
              type: element.data().type,
              isEdited: true,
              data: {
                file: {
                  name: element.data().message,
                  url: element.data().url,
                },
              },
            });
            //file:{img: "",name: 'default',},
            //
          } else {
            this.messageList.push({
              id: element.id,
              author: nombre,
              type: element.data().type,
              isEdited: true,
              data: {
                text: element.data().message,
                meta: moment(element.data().time).format("lll"),
              },
            });
          }

          //type: 'text', author: `me`, data: { text: `Say yes!` }
        });
      });
    },
    ConsultarColaboradores() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 18);
      parametros.append("idTarea", this.datos.idTarea);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              //this.Enviarnoty(element.idEmpleado);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    Enviarnoty(uid) {
      var nombre = "";
      db.collection("noty" + uid)
        .doc("notificacionPersonal" + sessionStorage.getItem("uid"))
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: sessionStorage.getItem("fullname"),
          type: "2",
          message: "New message: " + sessionStorage.getItem("nombre"),
          time: Date.now(),
          idEmpleado: sessionStorage.getItem("uid"),
          estatus: true,
          notificado: false,
        })
        .then(() => {
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      this.ConsultarMensajes();
    },
    CerrarSelect() {
      this.encargados = false;
      this.estatusSelect = false;
    },
    CerrarArchivos() {
      this.estatusArchivo = false;
      this.estatusArchivos = false;
    },
    BuscarImagen(message) {
      this.archivo = message.data.file;
      this.SubirImagen();
    },

    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + this.nodo)
          .child(this.archivo.name);

        const res = await refImagen.put(this.archivo);

        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURL(urlDescarga, this.archivo.name);
        this.Enviarnoty(sessionStorage.getItem("idEmpleadoChat"));
      } catch (error) {
        console.log(error);
      }
    },
    EnviarURL(url, name) {
      var nombre = sessionStorage.getItem("fullname");
      var urk = url;
      var mensaje = name;
      db.collection(this.nodo)
        .add({
          name: nombre,
          message: mensaje,
          url: url,
          type: "file",
          download: true,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
    },
    CambiarEstatusNotificacion(item) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection("noty" + nombre)
        .doc(item.id)
        .update({
          estatus: false,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    IngresarFoto() {
      this.dialogoFoto = true;
    },
    SubirFoto(event) {
      //alert("saludos");
      //console.log(event.name);
      this.SubirAvatar(event);
    },
    async SubirAvatar(event) {
      try {
        const refImagen = storage
          .ref()
          .child(sessionStorage.getItem("uid"))
          .child(event.name);

        const res = await refImagen.put(event);

        const urlDescarga = await refImagen.getDownloadURL();

        this.subirURLAvatar(urlDescarga);
      } catch (error) {
        console.log(error);
      }
    },
    subirURLAvatar(url) {
      db.collection("conexiones")
        .doc(sessionStorage.getItem("uid"))
        .update({
          //Nodo padre del que queremos sacar los nodos hijos
          url: url,
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    notificacionPush(element) {
      if (Notification.permission !== "granted") {
        Notification.requestPermission();
      } else {
        var notificacion = new Notification("My fastax", {
          icon: "http://myfastax.com/src/assets/X1.png",
          body: element.data().message,
        });

        notificacion.onclick = function () {
          if (element.data().type == 1) {
            window.location =
              "http://myfastax.com/#/TaskDetail/" + element.data().idTarea;
          } else {
            if (element.data().idEmpleado > sessionStorage.getItem("uid")) {
              this.nodo =
                "chat-" +
                element.data().idEmpleado +
                "-" +
                sessionStorage.getItem("uid");
            } else {
              this.nodo =
                "chat-" +
                sessionStorage.getItem("uid") +
                "-" +
                element.data().idEmpleado;
            }
            window.location =
              "http://myfastax.com/#/ChatNotificacion/" + this.nodo;
          }

          this.close();
        };
      }
    },
    MostrarDepartamentos() {
      this.idDepartamentos.forEach((element) => {
        if (element.idDepartamento == 12) {
          this.departamentos.payroll = true;
        }

        /*if(element.idDepartamento==45){
          this.departamentos.taxes=true;
        }*/

        if (element.idDepartamento == 88) {
          this.departamentos.bookkepping = true;
        }

        if (element.idDepartamento == 89) {
          this.departamentos.system = true;
        }

        if (element.idDepartamento == 90) {
          this.departamentos.support = true;
        }

        if (element.idDepartamento == 91) {
          this.departamentos.managers = true;
        }

        if (element.idDepartamento == 92) {
          this.departamentos.loans = true;
        }

        if (element.idDepartamento == 93) {
          this.departamentos.taxes = true;
        }
      });
    },
    CambiarEstatusNotificiacionActiva(id) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection("noty" + nombre)
        .doc(id)
        .update({
          notificado: true,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    InsertarCliente() {
      if (this.passwordEmpresa == this.passwordEmpresaConfirm) {
        let parametros = new URLSearchParams();
        parametros.append("accion", 9);
        parametros.append("nombreEmpresa", this.nombreEmpresa);
        parametros.append("nombreContacto", this.nombreContacto);
        parametros.append("telefonoContacto", this.telefonoContacto);
        parametros.append("idioma", this.idioma);
        parametros.append("correo", this.emailEmpresa);
        parametros.append("password", this.password);

        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
          data: parametros,
        })
          .then(
            function (response) {
              //console.log(response.data);
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log("Error" + response);
            }.bind(this)
          );
      } else {
        alert("Passwords do not match");
      }
    },
    VerListaClientes() {
      this.nombreCliente = "";
      this.nombreServicio = "list of companies";
      sessionStorage.removeItem("idCliente");
      EventBus.$emit("reload-table");
      this.$router.push("/Clientes").catch((failure) => {});
    },
    Permisos() {
      var notification = null;

      if (!("Notification" in window)) {
        // el navegador no soporta la API de notificaciones
        alert("Su navegador no soporta la API de Notificaciones :(");
        return;
      } else if (Notification.permission === "granted") {
        // Se puede emplear las notificaciones

        notification = new Notification("Hola Mundo");
      } else if (Notification.permission !== "denied") {
        // se pregunta al usuario para emplear las notificaciones
        Notification.requestPermission(function (permission) {
          if (permission === "granted") {
            notification = new Notification("Hola Mundo");
          }
        });
      }
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.filelist.clear();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      this.BuscarArchivo(event.dataTransfer.files);
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    BuscarArchivo(file) {
      this.dialog = true;
      this.archivo = file[0];
      this.SubirImagen();
    },
    NavegadorCerrado() {
      window.addEventListener("beforeunload", function (e) {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = "";
      });
    },
  },
};
document.addEventListener("DOMContentLoaded", function () {
  if (!Notification) {
    alert("las notificaciones no soportan en tu navegador, baja");
    return;
  }

  if (Notification.permission !== "granted") {
    Notification.requestPermission();
  }
});
</script>
