<script>

export default {
  components: {
  
  },
  data() {
    return {
      
      idCliente:sessionStorage.getItem("idCliente"),
      search: "",
      clientes:[],
      headers: [
        {
          text: "company",
          align: "start",
          sortable: false,
          value: "nombre",
          align: "start",
        },
        
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.ConsultarClientes();
    this.statusTools=false;
  },
  methods: {
    ConsultarClientes(){
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 1);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.clientes=response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ObtenerIdCliente(item){
      sessionStorage.setItem('idCliente',item.idCliente);
      this.idCliente=item.idCliente;
      
    }
  },
};
</script>