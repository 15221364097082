<template>
  <v-dialog
    v-model="estatus"
    persistent
    @keydown.esc="AccionCerrar"
    max-width="60%"
  >
    <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
      <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
      <v-tab>
        <v-btn text x-large
          ><v-icon left> mdi-page-next</v-icon>Task detail:
          <h5>{{ datos.nombreTarea }}</h5></v-btn
        >
      </v-tab>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-form ref="loginForm" lazy-validation>
              <v-card-text>
                <v-row dense>
                  <v-col
                    class="text-lg-right"
                    v-if="validador"
                    cols="12"
                    md="12"
                    sm="12"
                  >
                    <h2>ACTIONS TASK</h2>
                    <v-icon
                      x-large
                      v-if="datos.estatus != 'In progress'"
                      color="orange"
                      class="mr-2"
                      @click="TareaEnProceso(datos.idTarea, datos.fechaInicio)"
                    >
                      mdi-clock
                    </v-icon>

                    <v-icon
                      x-large
                      class="mr-2"
                      color="green"
                      v-if="datos.estatus != 'Finished'"
                      @click="TerminarTarea(datos.idTarea)"
                    >
                      mdi-calendar-check
                    </v-icon>

                    <v-icon
                      x-large
                      color="red"
                      class="mr-2"
                      v-if="datos.estatus != 'Canceled'"
                      @click="CancelarTarea(datos.idTarea)"
                    >
                      mdi-calendar-remove
                    </v-icon>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      label="Manager"
                      outlined
                      readonly
                      v-model="participanteChat"
                    ></v-text-field>
                    <v-text-field
                      label="Create on"
                      outlined
                      readonly
                      v-model="datos.fechaCreacion"
                    ></v-text-field>

                    <v-text-field
                      label="Start date"
                      outlined
                      readonly
                      v-model="datos.fechaInicio"
                    ></v-text-field>

                    <v-text-field
                      label="Finished on"
                      outlined
                      readonly
                      required
                      v-model="datos.fechaFin"
                    ></v-text-field>

                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="description"
                      :value="datos.descripcion"
                      readonly
                    ></v-textarea>
                  </v-col>

                  <v-col cols="9">
                    <v-container>
                      <h2>CHAT</h2>

                      <div
                        class="
                          flex
                          w-full
                          h-screen
                          items-center
                        
                         
                        "
                        id="app"
                      >
                        <div
                          class="p-12 bg-gray-100 border border-gray-300"
                          @dragover="dragover"
                          @dragleave="dragleave"
                          @drop="drop"
                        >
                          <input
                            type="file"
                            multiple
                            name="fields[assetsFieldHandle][]"
                            class="
                              w-px
                              h-px
                              opacity-0
                              overflow-hidden
                              absolute
                              d-none
                              filepond
                            "
                            @change="BuscarImagen"
                            ref="file"
                            accept=".pdf,.jpg,.jpeg,.png"
                          />

                          <label
                            for="assetsFieldHandle"
                            class="block cursor-pointer"
                          >
                            <div>
                              <Chat v-if="estatusChat" :datos="datos" />

                            </div>
                          </label>
                          <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                            <li class="text-sm p-1" v-for="file in filelist">
                              {{ file.name
                              }}<button
                                class="ml-2"
                                type="button"
                                @click="remove(filelist.indexOf(file))"
                                title="Remove file"
                              >
                                remove
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <Encargados
                        v-if="encargados"
                        @cerrar="CerrarSelect"
                        :datos="datos"
                        :estatus="estatusSelect"
                      />

                      <Archivos
                        v-if="estatusArchivo"
                        :nodo="datos.idTarea"
                        @cerrar="CerrarArchivos"
                        :estatus="estatusArchivos"
                      />

                      <input
                        type="file"
                        ref="boton"
                        class="d-done"
                        @change="BuscarImagen($event)"
                      />
                    </v-container>

                    <v-btn
                      color="#EF6C00"
                      class="mb-5 ml-5"
                      @click="(estatusArchivo = true), (estatusArchivos = true)"
                      large
                      dark
                    >
                      <v-icon x-large color="white"> mdi-file </v-icon>
                    </v-btn>

                    <v-btn
                      color="#EF6C00"
                      class="mb-5 ml-5"
                      @click="confirmacion = true"
                      large
                      dark
                      v-if="rol == 1"
                    >
                      <v-icon x-large color="white"> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row dense>
                  <v-col cols="6" sm="1">
                    <v-btn
                      x-large
                      block
                      color="#EF6C00"
                      dark
                      @click="AccionCerrar(), (estatusChat = false)"
                    >
                      Close
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="confirmacion" persistent max-width="25%">
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab>
          <v-icon right>mdi-delete</v-icon>
        </v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-card-text>
                    <v-row cols="12" sm="3" md="6">
                      <v-col
                        ><h3>
                          Are you sure you want to delete this task?
                        </h3></v-col
                      >
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      large
                      color="#EF6C00"
                      @click="confirmacion = false"
                      dark
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="#EF6C00"
                      large
                      dark
                      @click="EliminarTarea(), (estatusChat = false)"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>
  </v-dialog>
</template>

<script>
import Chat from "../components/chat";
import Encargados from "../components/Encargados";
import { firebase, storage, db } from "../firebase";
import Archivos from "../components/Archivos";

export default {
  components: {
    Chat,
    Encargados,
    Archivos,
  },
  props: {
    //file:null,
    estatusArchivo: false,
    estatus: Boolean,
    datos: {
      type: Object,
      default: () => [],
    },
    idTarea: {
      type: String,
      default: () => "",
    },
    validador: {
      type: Boolean,
      default: true,
    },
  },
  delimiters: ["${", "}"],
  data: () => ({
    filelist: [],
    rol: sessionStorage.getItem("rol"),
    participanteChat: "",
    file: null,
    urlTemporal: "",
    encargados: false,
    estatusChat: true,
    rol: sessionStorage.getItem("rol"),
    estatusSelect: false,
    estatusArchivo: false,
    confirmacion: false,
  }),
  mounted() {
    console.log(this.datos);
    this.ConsultarEmpleadosXTarea();
  },
  methods: {
    ConsultarColaboradores() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 6);
      parametros.append("idTarea", idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            alert("Tarea cancelada");
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EnviarURL(url, name) {
      var nombre = sessionStorage.getItem("fullname");
      var urk = url;
      var mensaje = name;
      db.collection(this.datos.idTarea)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          message: mensaje,
          url: url,
          download: true,
          time: Date.now(),
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      this.ConsultarColaboradores();
    },
    ConsultarColaboradores() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 18);
      parametros.append("idTarea", this.datos.idTarea);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              console.log(element);
              this.Enviarnoty(element.idEmpleado);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    Enviarnoty(uid) {
      var nombre = "";
      db.collection("noty" + uid)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          type: "1",
          message: "New message:  " + this.datos.nombreTarea,
          time: Date.now(),
          idTarea: this.datos.idTarea,
          estatus: true,
          notificado: false,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + this.datos.idTarea)
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        console.log(urlDescarga);
        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
    BuscarImagen(event) {
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    CerrarSelect() {
      this.encargados = false;
    },
    TerminarTarea(idTarea) {
      let parametros = new URLSearchParams();

      parametros.append("accion", 5);
      parametros.append("idTarea", idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            alert("Finished");
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    CancelarTarea(idTarea) {
      let parametros = new URLSearchParams();

      parametros.append("accion", 6);
      parametros.append("idTarea", idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            alert("Canceled");
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    TareaEnProceso(idTarea, fechaInicio) {
      if (fechaInicio == "0000-00-00") {
        this.dialogCalendar = true;
      } else {
        let parametros = new URLSearchParams();

        parametros.append("accion", 7);
        parametros.append("idTarea", idTarea);

        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
          data: parametros,
        })
          .then(
            function (response) {
              alert("In process");
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log(response);
            }.bind(this)
          );
      }
    },
    AccionCerrar() {
      this.$emit("cerrar");
    },
    async subirImagen() {
      try {
        const refImagen = storage.ref().child("Archivos" + datos.idTarea);

        const res = await refImagen.put(file);
      } catch (error) {
        conole.log(error);
      }
    },
    CerrarArchivos() {
      this.estatusArchivo = false;
    },
    LimpiarNotificaciones() {
      var nombre = sessionStorage.getItem("uid");
      let ref = db.collection("noty" + nombre).doc("");

      ref
        .update({
          notificacion: false,
        })
        .then(function () {
          this.AccionMenu(item.type);
          //this.Notificar();
          //console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error al aceptar notificación: ", error);
        });
    },
    EliminarTarea() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 8);
      parametros.append("idTarea", this.datos.idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response);
            this.AccionCerrar();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EventoCerrarESC() {
      alert("evento esc");
    },
    ConsultarEmpleadosXTarea() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 7);
      parametros.append("idTarea", this.datos.idTarea);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data[0].idEmpleado);
            this.participanteChat = response.data[0].nombre;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.filelist.clear();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      this.BuscarArchivo(event.dataTransfer.files);
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    BuscarArchivo(file) {
      this.dialog = true;
      this.file = file[0];
      this.SubirImagen();
    },
  },
};
</script>
