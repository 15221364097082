<template>
  <v-layout>
    <v-row class="no-gutters elevation-12">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive
          v-if="activeChat"
          class="overflow-y-hidden fill-height"
          height="400"
        >
          <v-card flat class="d-flex flex-column fill-height">
            <v-card-title> </v-card-title>

            <v-card-text class="flex-grow-1 overflow-y-auto" v-chat-scroll>
              <template>
                <div
                  class="text-xs-right"
                  v-for="(item, index) in messages"
                  :key="index"
                  :class="
                    item.name == nombreEncargado
                      ? 'd-flex flex-row-reverse'
                      : ''
                  "
                >
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-hover v-slot:default="{ hover }">
                        <v-sheet
                          :color="
                            item.name == nombreEncargado ? '#4E8CFF' : '#EF6C00'
                          "
                          dark
                          @click="downloadItem(item.message)"
                          class="v-chip-chat pa-4 mb-2"
                          v-on="on"
                        >
                          {{ item.message }}
                          <v-icon v-if="item.url" left small>
                            mdi-download
                          </v-icon>

                          <br />
                          <sub
                            v-if="hover"
                            class="ml-2"
                            style="font-size: 0.5rem"
                            >{{ item.time + " by " + item.name }}</sub
                          >
                        </v-sheet>
                      </v-hover>
                    </template>
                  </v-menu>
                </div>
              </template>
            </v-card-text>

            <v-card-text class="flex-shrink-1">
              <v-form @submit.prevent="enviarMensaje" v-model="valido">
                <v-textarea
                  id="CajaMensaje"
                  v-model="messageForm.content"
                  label=""
                  placeholder="Enter your message"
                  type="text"
                  no-details
                  clearable
                  required
                  outlined
                  rows="1"
                  color="#000000"
                  append-outer-icon="mdi-send"
                  @keyup.enter="EnviarMensaje"
                  :rules="reglas"
                  @click:append-outer="EnviarMensaje"
                  hide-details
                />
              </v-form>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-layout>
</template>

<style>
.a {
  color: aliceblue;
}
</style>

<script>
import Vue from "vue";
import * as easings from "vuetify/es5/services/goto/easing-patterns";
import { db, storage } from "../firebase";
import moment from "moment";
import VueChatScroll from "vue-chat-scroll";
import EventBus from "../bus";
Vue.use(VueChatScroll);

var time = new Date();

export default {
  props: {
    datos: String,
  },
  data: () => ({
    valido: false,
    nombreEncargado: "",
    messages: [],
    activeChat: 1,
    firebase: null,
    messageForm: {
      content: "",
      download: false,
      created_at: "11:11am",
    },
    reglas: [(v) => !!v || "Message is required"],
  }),
  mounted() {
    this.nombreEncargado = sessionStorage.getItem("fullname");
    this.ConsultarMensajes();
    EventBus.$emit("hint-taskcenter");
    //this.InicializarFirebase();
    //this.InicializarChat();
  },
  methods: {
    LimpiarNotificaciones(id) {
      /*
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection(this.datos.idTarea).doc(id);
        
        ref.update({
          notificacion:false
        }).where("name","!=",sessionStorage.getItem("nombre")).where("notificacion", "==", true);

        */
    },
    downloadItem(name) {
      storage
        .ref()
        .child("Archivos" + this.datos)
        .child(name)
        .getDownloadURL()
        .then(function(url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function(response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function(response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function(error) {
          console.log(error);
          // Handle any errors
        });
    },
    send: function() {
      this.chat.push({
        from: "user",
        item: this.item,
      });
      this.item = null;
      this.addReply();
    },
    addReply() {
      this.chat.push({
        from: "sushant",
        item: "Hmm",
      });
    },
    InicializarFirebase() {
      var firebaseConfig = {
        apiKey: "AIzaSyALkXRnymBRQ1adeEkKS6TquOnT8UtUnWQ",
        authDomain: "prueba-b309a.firebaseapp.com",
        databaseURL: "https://prueba-b309a.firebaseio.com",
        projectId: "prueba-b309a",
        storageBucket: "prueba-b309a.appspot.com",
        messagingSenderId: "920452446578",
        appId: "1:920452446578:web:5098093de56a44ba1745eb",
        measurementId: "G-B1N8S91TC6",
      };

      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.analytics();
      this.firebase = firebase;

      // Initialize Firebase
      //this.firebase.initializeApp(firebaseConfig);
      // this.firebase.analytics();
    },
    InicializarChat() {
      this.firebase
        .database()
        .ref(this.datos)
        .on(
          "value",
          function(snapshot) {
            // this.messages = snapshot;
            this.messages = [];
            snapshot.forEach(
              function(e) {
                var element = e.val();

                this.messages.push(element);

                //var nombre = element.name;
              }.bind(this)
            );
          }.bind(this)
        );
    },
    EnviarMensaje() {
      if (this.valido) {
        var nombre = sessionStorage.getItem("fullname");
        var mensaje = this.messageForm.content;
        console.log(this.datos);
        this.messageForm.content = "";
        db.collection(this.datos)
          .add({
            //Nodo padre del que queremos sacar los nodos hijos
            name: nombre,
            message: mensaje,
            me: true,
            url: "",
            download: false,
            time: Date.now(),
            notificacion: true,
            idEmpleado: sessionStorage.getItem("uid"),
            type: "text",
            status:1
          })
          .catch((error) => {
            console.log(error);
          }); //promesa para el error
        this.ConsultarMensajes();
        //this.ConsultarColaboradores();
        var temp = this.datos.split('-');
        temp.forEach((element) => {
          if(element!=sessionStorage.getItem("uid") && element!='chat'){
            this.Enviarnoty(element);
          }
        })
        
        
       
      }
    },
    ConsultarMensajes() {
      moment.locales("es");

      let ref = db.collection(this.datos).where("status", "==", 1).orderBy("time"); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        this.messages = [];
        let nombre;

        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes
          this.messages.push({
            id: element.id,
            name: element.data().name,
            message: element.data().message,
            me: element.data().me,
            url: element.data().url,
            download: element.data().download,
            time: moment(element.data().time).format("lll"),
            status: element.data().status,
          });
       
         
          if (sessionStorage.getItem("fullname") != element.data().name) {
            nombre = element.data().name;
             this.RegresarNombre(element.data().name); // esta funciona regresa el nombre de la persona del chat
          }

          this.LimpiarNotificaciones(element.id);
        });
        
      });
    },
    RegresarNombre(nombre) {
      console.log(nombre);
      //
      this.$emit("showName", nombre);
    },
    ConsultarColaboradores() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 18);
      // parametros.append("idTarea", this.datos.idTarea);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function(response) {

            response.data.forEach((element) => {
              this.Enviarnoty(element.idEmpleado);
            });

          }.bind(this)
        )
        .catch(
          function(response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    Enviarnoty(idDestinatario) {
      var nombre = "";
      nombre=sessionStorage.getItem("fullname");
      db.collection("noty" +idDestinatario)
        .doc("notificacionTarea" + this.datos)
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: sessionStorage.getItem("fullname"),
          type: "2",
          message: "New message:  " + sessionStorage.getItem("nombre"),
          time: Date.now(),
          idEmpleado: sessionStorage.getItem("uid"),
          estatus: true,
          notificado: false,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      this.ConsultarMensajes();
    },
  },
};
</script>

<style>
.v-chip-chat {
  max-height: 3000px;
  max-width: 330px;
  border-radius: 20px;
}
</style>
