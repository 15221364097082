<template>
  <v-row class="ml-8">
    <v-col cols="12" sm="3" class="mt-16">
      <v-menu
        class="hidden-sm-and-down"
        v-for="([texto, rounded], index) in btns1"
        :key="texto"
        :rounded="rounded"
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="hidden-sm-and-down dark--text"
            :color="colors[index]"
            v-bind="attrs"
            v-on="on"
            text
          >
            Bookkeeping
          </v-btn>
        </template>

        <v-list :if="departamentos.bookkepping">
          <v-list-item
            v-on:click="EnviarAccion(item)"
            v-for="item in toolsBookkeeping"
            :key="item"
            link
          >
            <v-list-item-title v-text="item"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <v-col cols="1" sm="3" class="mt-16">
      <v-menu
        class="ml-7 hidden-sm-and-down"
        v-for="([texto, rounded], LoansIndex) in btns6"
        :key="texto"
        :rounded="rounded"
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="ml-7 hidden-sm-and-down"
            :color="colors[LoansIndex]"
            v-bind="attrs"
            v-on="on"
            text
          >
            Loans
          </v-btn>
        </template>

        <v-list :if="departamentos.loans">
          <v-list-item
            v-on:click="EnviarAccion(item)"
            v-for="item in toolsLoands"
            :key="item"
            link
          >
            <v-list-item-title v-text="item"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <v-col cols="1" sm="3" class="mt-16">
      <v-menu
        class="hidden-sm-and-down"
        v-for="([tex, rounded], index) in btns2"
        :key="tex"
        :rounded="rounded"
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            :color="colors[index]"
            class="hidden-sm-and-down dark--text"
            v-bind="attrs"
            v-on="on"
            text
          >
            Payroll
          </v-btn>
        </template>

        <v-list v-if="departamentos.payroll">
          <v-list-item
            v-on:click="EnviarAccion(items)"
            v-for="items in toolsPayroll"
            :key="items"
            link
          >
            <v-list-item-title v-text="items"></v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list v-else>
          <v-list-item
            v-on:click="EnviarAccion(item)"
            v-for="item in toolsPayrollBook"
            :key="item"
            link
          >
            <v-list-item-title v-text="item"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <v-col cols="1" sm="3" class="mt-16">
      <v-menu
        class="hidden-sm-and-down"
        v-for="([texto, rounded], taxesIndex) in btns5"
        :key="texto"
        :rounded="rounded"
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="hidden-sm-and-down dark--text"
            :color="colors[taxesIndex]"
            v-bind="attrs"
            v-on="on"
            text
          >
            Taxes
          </v-btn>
        </template>

        <v-list :if="departamentos.taxes">
          <v-list-item
            v-on:click="EnviarAccion(item)"
            v-for="item in toolsTaxes"
            :key="item"
            link
          >
            <v-list-item-title v-text="item"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import main from "../App";
import EventBus from "../bus";
export default {
  props: {
    estatus: Boolean,
  },
  components: {
    main,
  },
  data: () => ({
    colors: ["", "error", "teal darken-1"],
    idDepartamentos: [],
    accion: "",
    toolsAdmin: ["Task Manager", "Task Center", "Users"],
    tools: ["Task Center"],
    departamentos: {
      payroll: false,
      bookkepping: false,
      system: false,
      support: false,
      managers: false,
      loans: false,
      taxes: false,
    },
    toolsBookkeeping: ["Excel Editor", "Vendors", "Sales Taxes", "Reports"],
    toolsPayroll: [
      "Checks",
      "Reports Payroll",
      "Forms",
      "Employee",
      "Company",
      "Other",
    ],
    toolsCliente: ["Bookkeeping"],
    toolsPayrollBook: [
      "Checks",
      "Reports Payroll",
      "Forms",
      "Employee",
      "Company",
      "Other",
    ], //["Reports", "Documents"],
    toolsLoands: ["Tools"],
    toolsTaxes: ["Tools"],
    btns1: [["Custom1", "b-xl"]],
    btns2: [["Custom2", "b-xl"]],
    btns3: [["Custom3", "b-xl"]],
    btns4: [["Custom4", "b-xl"]],
    btns5: [["Custom5", "b-xl"]],
    btns6: [["Custom6", "b-xl"]],
  }),
  methods: {
    addComment: function (addComment) {
      EventBus.$emit(
        "add-comment",
        addComment,
        sessionStorage.getItem("cliente")
      );
    },
    AccionMenu(accion) {
      this.accion = accion;
    },
    EnviarAccion(accion) {
      this.addComment(accion);
      this.$emit("AccionMenu", accion);
    },
    MostrarDepartamentos() {
      this.idDepartamentos.forEach((element) => {
        if (element.idDepartamento == 12) {
          this.departamentos.payroll = true;
        }

        /*if(element.idDepartamento==45){
          this.departamentos.taxes=true;
        }*/

        if (element.idDepartamento == 88) {
          this.departamentos.bookkepping = true;
        }

        if (element.idDepartamento == 89) {
          this.departamentos.system = true;
        }

        if (element.idDepartamento == 90) {
          this.departamentos.support = true;
        }

        if (element.idDepartamento == 91) {
          this.departamentos.managers = true;
        }

        if (element.idDepartamento == 92) {
          this.departamentos.loans = true;
        }

        if (element.idDepartamento == 93) {
          this.departamentos.taxes = true;
        }
      });
    },
  },
  mounted() {
    this.idDepartamentos = JSON.parse(sessionStorage.getItem("idDepartamento"));
    this.MostrarDepartamentos();
  },
};
</script>

<style>
</style>